import { useMemo } from "react";

import { SvgIcon } from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";
import classNames from "clsx";

import DeleteActionButton from "../action-buttons/delete-action-button";

import { Link } from "~/components/routing";
import Image from "~/components/suite-ui/image";
import Spinner from "~/components/suite-ui/spinner";
import useGetAssetsUrl from "~/hooks/use-get-assets-url";
import { getBlobInfoQuery } from "~/models/blobs";
import { MIME_TYPE_EXTENSION_MAP, SUPPORTED_IMG_MIME_TYPES } from "~/util/base-values";

export interface IBlobPreviewProps {
  blobId: string;
  blobUrl?: string;
  alt?: string;
  onDelete?: () => void;
  containerClassName?: string;
  previewClassName?: string;
  documentPlaceholderClassName?: string;
  loadingPlaceholderClassName?: string;
  /** Defaults to `true`. */
  enlarge?: boolean;
  imageSize?: number | [width: number, height: number];
}

const CONTAINER_BORDER =
  "rounded border border-solid border-[color:var(--legacy-eva-color-light-3)] object-contain";

const BlobPreview = ({
  alt,
  blobId,
  blobUrl,
  containerClassName,
  documentPlaceholderClassName,
  enlarge = true,
  imageSize,
  loadingPlaceholderClassName,
  onDelete,
  previewClassName,
}: IBlobPreviewProps) => {
  const assetUrl = useGetAssetsUrl();
  const { data: blobInfo, isFetching: isBlobInfoLoading } = useQuery({
    ...getBlobInfoQuery({ Guid: blobId }),
    refetchOnWindowFocus: false,
  });

  const isImage = useMemo(
    () => blobInfo?.MimeType && SUPPORTED_IMG_MIME_TYPES.includes(blobInfo?.MimeType),
    [blobInfo?.MimeType],
  );

  const imageExtension = useMemo(() => {
    if (blobInfo?.MimeType) {
      return MIME_TYPE_EXTENSION_MAP[blobInfo?.MimeType];
    }
    return ".png";
  }, [blobInfo?.MimeType]);

  const [width, height] = useMemo(() => {
    if (!imageSize) {
      return [48, 48];
    }
    if (Array.isArray(imageSize)) {
      return imageSize;
    }
    return [imageSize, imageSize];
  }, [imageSize]);

  // TODO: display the name of document / image
  return isBlobInfoLoading ? (
    <div className={classNames(containerClassName, "group")}>
      <div
        className={classNames(
          "flex items-center justify-center",
          CONTAINER_BORDER,
          loadingPlaceholderClassName,
        )}
      >
        <Spinner className="h-4 w-4" />
      </div>
    </div>
  ) : blobInfo ? (
    <div className={classNames(containerClassName, "group")}>
      <div className="relative h-full">
        {blobUrl || blobId ? (
          <>
            {isImage && assetUrl ? (
              <Image
                src={blobUrl ?? `${assetUrl}/image/${width}/${height}/${blobId}${imageExtension}`}
                srcBig={
                  enlarge
                    ? blobUrl ?? `${assetUrl}/image/${width}/${height}/${blobId}${imageExtension}`
                    : undefined
                }
                alt={alt ?? blobId}
                className={classNames(CONTAINER_BORDER, previewClassName)}
                enlarge={enlarge}
              />
            ) : (
              <Link
                target="_blank"
                to={blobUrl ?? `${assetUrl}/image/${width}/${height}/${blobId}${imageExtension}`}
              >
                <div
                  className={classNames(
                    "cursor-pointer",
                    "flex items-center justify-center",
                    CONTAINER_BORDER,
                    documentPlaceholderClassName,
                  )}
                >
                  <SvgIcon
                    name="document"
                    className="h-14 w-14 text-[color:var(--legacy-eva-apple-color-purple)]"
                  />
                </div>
              </Link>
            )}
          </>
        ) : null}

        {onDelete && (blobUrl || blobId) && !isBlobInfoLoading ? (
          <div className="duration-125 absolute bottom-0 right-0 opacity-0 transition-opacity group-hover:opacity-100">
            <div
              className={classNames(
                "flex h-[40px] bg-surface-primary",
                "rounded-br-[4px] rounded-tl-[4px]",
                "border border-solid border-[color:var(--legacy-eva-color-light-3)]",
              )}
            >
              <DeleteActionButton className="rounded-none border-0" onPress={onDelete} />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};

export default BlobPreview;
