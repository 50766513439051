import { useMemo } from "react";
import { useIntl } from "react-intl";

import { IChapter } from "../chapter-finder.types";

import {
  useHasFunctionality,
  useHasFunctionalityWithoutScope,
} from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const useControlRoomChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: settingsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.SETTINGS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: appSettingsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.APP_SETTINGS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: monitorsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.MONITORS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: synchronizationFunctionalityAllowed } = useHasFunctionality(
    Functionalities.SYNCHRONIZATION,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: apiUsersChapterAllowed } = useHasFunctionality(
    Functionalities.API_USERS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: infrastructureChapterAllowed } = useHasFunctionality(
    Functionalities.INFRASTRUCTURE,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewMessageTemplatesAllowed } = useHasFunctionality(
    Functionalities.MESSAGE_TEMPLATES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewErrorQueueAllowed } = useHasFunctionality(
    Functionalities.ERROR_QUEUE,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewSingleSignOnAllowed } = useHasFunctionality(
    Functionalities.OPEN_ID_PROVIDERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewLiveGuardAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.LIVE_GUARD,
  );

  const { functionalityAllowed: viewWatchtowersAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.WATCHTOWERS,
  );

  const { functionalityAllowed: viewEventExportConfigurationsAllowed } =
    useHasFunctionalityWithoutScope(Functionalities.EVENT_EXPORT_CONFIGURATION);

  const { functionalityAllowed: viewRecurringTasksAllowed } = useHasFunctionality(
    Functionalities.RECURRING_TASKS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewScriptsAllowed } = useHasFunctionality(
    Functionalities.SCRIPTS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewAssortmentContentCulturesAllowed } = useHasFunctionality(
    Functionalities.ASSORTMENT_CONTENT_CULTURES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewContentCultureMappingAllowed } = useHasFunctionality(
    Functionalities.CONTENT_CULTURE_MAPPING,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewEntityFieldValidatorsAllowed } = useHasFunctionality(
    Functionalities.ENTITY_FIELD_VALIDATORS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewCustomFieldsAllowed } = useHasFunctionality(
    Functionalities.CUSTOM_FIELDS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewSerialNumbersAllowed } = useHasFunctionality(
    Functionalities.SERIAL_NUMBERS,
    FunctionalityScope.View,
  );

  const controlRoomChapters = useMemo<IChapter[]>(() => {
    const chapters: IChapter[] = [];

    if (settingsFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.settings.chapters.settings",
          defaultMessage: "Settings",
        }),
        path: "/settings",
      });
    }

    if (appSettingsFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.app-settings",
          defaultMessage: "App Settings",
        }),
        path: "/app-settings",
      });
    }

    if (monitorsFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.monitors.chapters.monitors",
          defaultMessage: "Monitors",
        }),
        path: "/monitors",
      });
    }

    if (synchronizationFunctionalityAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "synchronization.chapter.title",
          defaultMessage: "Synchronization",
        }),
        path: "/synchronization",
      });
    }

    if (apiUsersChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.api-users",
          defaultMessage: "API Users",
        }),
        path: "/api-users",
      });
    }

    if (infrastructureChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.infrastructure",
          defaultMessage: "Infrastructure",
        }),
        path: "/infrastructure",
      });
    }

    if (viewMessageTemplatesAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.control-room.chapters.stencil",
          defaultMessage: "Stencil",
        }),
        path: "/stencil",
      });
    }

    if (viewErrorQueueAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.error-queue",
          defaultMessage: "Error Queue",
        }),
        path: "/error-queue",
      });
    }

    if (viewSingleSignOnAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.single-sign-on",
          defaultMessage: "Single Sign-On",
        }),
        path: "/single-sign-on",
      });
    }

    if (viewLiveGuardAllowed || viewWatchtowersAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.watchtower",
          defaultMessage: "Watchtower",
        }),
        path: "/watchtower",
      });
    }

    if (viewEventExportConfigurationsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.event-export-configuration",
          defaultMessage: "Event Export Configuration",
        }),
        path: "/event-export-configurations",
      });
    }

    if (viewRecurringTasksAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.scheduled-tasks",
          defaultMessage: "Scheduled Tasks",
        }),
        path: "/scheduled-tasks",
      });
    }

    if (viewScriptsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.scripting",
          defaultMessage: "Scripting",
        }),
        path: "/scripting",
      });
    }

    //todo: check for functionality when that will be created
    chapters.push({
      title: intl.formatMessage({
        id: "generic.title.async-requests",
        defaultMessage: "Async Requests",
      }),
      path: "/async-requests",
    });

    if (viewAssortmentContentCulturesAllowed || viewContentCultureMappingAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.cultures",
          defaultMessage: "Cultures",
        }),
        path: "/cultures",
      });
    }

    if (viewEntityFieldValidatorsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.entity-field-validators",
          defaultMessage: "Entity Field Validators",
        }),
        path: "/entity-field-validators",
      });
    }

    // open cash drawer reasons requires no functionality for viewing the list
    chapters.push({
      title: intl.formatMessage({
        id: "generic.label.reasons",
        defaultMessage: "Reasons",
      }),
      path: "/reasons",
    });

    if (viewCustomFieldsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.custom-fields",
          defaultMessage: "Custom Fields",
        }),
        path: "/custom-fields",
      });
    }

    // This chapter does not require functionality checks since the overview is based on `ListCountries`
    chapters.push({
      title: intl.formatMessage({
        id: "generic.title.address-requirements",
        defaultMessage: "Address Requirements",
      }),
      path: "/address-requirements",
    });

    if (viewSerialNumbersAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "serial-number-configuration.chapter.title",
          defaultMessage: "Serial Number Configuration",
        }),
        path: "/serial-number-configuration",
      });
    }

    return chapters;
  }, [
    settingsFunctionalityAllowed,
    appSettingsFunctionalityAllowed,
    monitorsFunctionalityAllowed,
    synchronizationFunctionalityAllowed,
    apiUsersChapterAllowed,
    infrastructureChapterAllowed,
    viewMessageTemplatesAllowed,
    viewErrorQueueAllowed,
    viewSingleSignOnAllowed,
    viewLiveGuardAllowed,
    viewWatchtowersAllowed,
    viewEventExportConfigurationsAllowed,
    viewRecurringTasksAllowed,
    viewScriptsAllowed,
    intl,
    viewAssortmentContentCulturesAllowed,
    viewContentCultureMappingAllowed,
    viewEntityFieldValidatorsAllowed,
    viewCustomFieldsAllowed,
    viewSerialNumbersAllowed,
  ]);

  return controlRoomChapters;
};
