import { json } from "react-router-dom";

import { helpers } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { QueryClient } from "@tanstack/react-query";

import { LoginActionData } from "./login.types";
import { loginCredentialsFormSchema } from "./login-credentials-form-fields";
import { handleLoginError } from "./utils";

import { confirmTwoFactorAuthenticationTokenSchema } from "~/features/login/confirm-two-factor-authentication-token-form-fields";
import { getApplicationConfigurationLoaderQuery } from "~/models/application";
import { getAuthorizationStructureLoaderQuery } from "~/models/auth";
import { getCurrentUserLoaderQuery } from "~/models/users";
import { unauthorizedRequestsStrategy } from "~/routes/__auth/unauthorized-requests-strategy";
import { mutate } from "~/util/mutate";
import { queryClient } from "~/util/query-client";
import { parseZodFormErrors } from "~/util/validators/parse-zod-form-errors";

const { storage } = helpers;

export const loginService = mutate({
  service: Core.Login,
  disabledNotifications: true,
  disableErrorNotification: true,
  disableRedirectOn401: true,
});

const refetchCrucialUserQueries = async () => {
  const preparedCurrentUserQuery = getCurrentUserLoaderQuery(queryClient, {}, ["root"]);
  const preparedGetAuthorizationStructureQuery = getAuthorizationStructureLoaderQuery(
    queryClient,
    {},
    ["root"],
  );
  const preparedGetApplicationConfiguration = getApplicationConfigurationLoaderQuery(
    queryClient,
    {},
    ["root"],
  );
  queryClient.removeQueries(["root"]);
  queryClient.clear();

  return await Promise.all([
    preparedCurrentUserQuery(),
    preparedGetAuthorizationStructureQuery(),
    preparedGetApplicationConfiguration(),
  ]);
};

export const loginByCredentials = async (
  queryClient: QueryClient,
  formValues: { [k: string]: FormDataEntryValue },
) => {
  const form = loginCredentialsFormSchema.safeParse(formValues);

  if (!form.success) {
    return json<LoginActionData>({
      success: false,
      formErrors: parseZodFormErrors(form.error),
    });
  }

  const formData = form.data;

  const loginResponse = await loginService(
    {
      AsEmployee: true,
      Username: formData.Username,
      Password: formData.Password,
      SelectFirstOrganizationUnit: true,
    },
    { authenticationToken: undefined },
    false,
  );

  if (loginResponse?.response?.FailureReason) {
    return json<LoginActionData>({
      success: false,
      serviceError: handleLoginError(loginResponse.response),
    });
  }

  // if there is only 1 2FA method, automatically select it. If there are more, leave it undefined
  const chosenAuthenticatorName =
    loginResponse?.response?.TwoFactorAuthenticators?.length &&
    loginResponse?.response?.TwoFactorAuthenticators.length === 1
      ? loginResponse?.response?.TwoFactorAuthenticators[0].Name
      : undefined;

  if (loginResponse?.response?.Authentication === 2) {
    await storage.setItem("evaUserToken", loginResponse?.response?.User?.AuthenticationToken ?? "");
    await refetchCrucialUserQueries();
    console.log("[SESSION]: Query Cache has been cleared");
  }

  // reset unauthorized response handling strategy
  unauthorizedRequestsStrategy.reset();

  return json<LoginActionData>({
    success: true,
    loginResponse: loginResponse?.response,
    chosenAuthenticatorName,
  });
};

export const confirm2FA = async (
  queryClient: QueryClient,
  formValues: { [k: string]: FormDataEntryValue },
) => {
  const form = confirmTwoFactorAuthenticationTokenSchema.safeParse(formValues);

  if (!form.success) {
    return json<LoginActionData>({
      success: false,
      formErrors: parseZodFormErrors(form.error),
    });
  }

  const formData = form.data;

  const loginResponse = await loginService(
    {
      AsEmployee: true,
      Username: formData.UserName,
      AuthenticationToken: formData.AuthenticationToken,
      TwoFactorAuthenticator: formData.TwoFactorAuthenticator,
      TwoFactorAuthenticationToken: formData.TwoFactorAuthenticationToken,
      TwoFactorRememberMe: formData.TwoFactorAuthenticationRememberMe,
      SelectFirstOrganizationUnit: true,
    },
    { authenticationToken: undefined },
    false,
  );

  if (loginResponse?.response?.FailureReason) {
    return json<LoginActionData>({
      success: false,
      serviceError: handleLoginError(loginResponse.response),
      loginResponse: loginResponse?.response,
    });
  }

  if (loginResponse?.response?.Authentication === 2) {
    await storage.setItem("evaUserToken", loginResponse?.response?.User?.AuthenticationToken ?? "");
    await refetchCrucialUserQueries();
    console.log("[SESSION]: Query Cache has been cleared");
  }

  // reset unauthorized response handling strategy
  unauthorizedRequestsStrategy.reset();

  return json<LoginActionData>({
    success: true,
    loginResponse: loginResponse?.response,
  });
};
