import { ProtocolMode, PublicClientApplication } from "@azure/msal-browser";
import { OIDC_DEFAULT_SCOPES } from "@azure/msal-common";
import { Core } from "@springtree/eva-services-core";
import { v4 as uuid } from "uuid";

import { intlAccessor } from "~/util/intl-accessor";
import { mutate } from "~/util/mutate";

const mutateLogin = mutate({ service: Core.Login, disabledNotifications: true });

export const loginViaCredentials = async (userName: string, password: string) => {
  return mutateLogin({
    AsEmployee: true,
    Password: password,
    Username: userName,
    SelectFirstOrganizationUnit: true,
  });
};

export const loginViaSSO = async (
  provider: Pick<
    EVA.Authentication.OpenID.AvailableOpenIDConfiguration,
    "BaseUrl" | "ClientID" | "ID"
  >,
) => {
  const pca = new PublicClientApplication({
    auth: {
      clientId: provider.ClientID,
      authority: provider.BaseUrl,
      knownAuthorities: [provider.BaseUrl],
      protocolMode: ProtocolMode.OIDC,
    },
  });

  // This looks hacky, and it is, but it's the only way to get the default scopes to work
  // because the library that we're using will always for offline_access to be in there
  // which fails for some SSO providers, like the one that Burberry uses
  // Relevant link for other people also enjoying this issue: https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3649
  if (OIDC_DEFAULT_SCOPES.indexOf("offline_access") >= 0) {
    OIDC_DEFAULT_SCOPES.splice(OIDC_DEFAULT_SCOPES.indexOf("offline_access"), 1);
  }

  const result = await pca.loginPopup({
    scopes: ["openid", "profile", "email"],
    redirectUri: "/login",
    state:
      window.location.pathname === "/login"
        ? undefined
        : JSON.stringify({
            uuid: uuid(),
            redirectPath: `${window.location.pathname}${window.location.search}`,
          }),
  });

  return result;
};

//TODO - Remove this extended FailureReason enum when the API is updated
export enum AuthenticationFailureReasons {
  None = 0,
  InvalidRequest = 1,
  NeedsEmailVerification = 2,
  NotAuthorized = 3,
  InvalidCredentials = 4,
  ThirdPartyFailure = 5,
  InvalidUserType = 6,
  NeedsPasswordReset = 7,
  AuthenticationOnPrimaryRegionFailed = 8,
  SelectedOrganizationUnitNotAvailable = 9,
  UserDoesntExist = 10,
  UserDeactivated = 11,
  InvalidTwoFactorAuthentication = 12,
}

export const handleLoginError = (loginResponse?: EVA.Core.LoginResponse) => {
  switch (loginResponse?.FailureReason as AuthenticationFailureReasons | undefined) {
    case AuthenticationFailureReasons.InvalidRequest:
      return intlAccessor.formatMessage({
        id: "login.failure.invalid-request",
        defaultMessage: "Invalid request",
      });
    case AuthenticationFailureReasons.NeedsEmailVerification:
      return intlAccessor.formatMessage({
        id: "login.failure.needs-email-verification",
        defaultMessage: "Needs email verification",
      });
    case AuthenticationFailureReasons.NotAuthorized:
      return intlAccessor.formatMessage({
        id: "login.failure.not-authorized",
        defaultMessage: "Not authorized",
      });
    case AuthenticationFailureReasons.InvalidCredentials:
      return intlAccessor.formatMessage({
        id: "login.failure.invalid-credentials",
        defaultMessage: "Invalid credentials",
      });
    case AuthenticationFailureReasons.ThirdPartyFailure:
      return intlAccessor.formatMessage({
        id: "login.failure.third-party-failure",
        defaultMessage: "Third party failure",
      });
    case AuthenticationFailureReasons.InvalidUserType:
      return intlAccessor.formatMessage({
        id: "login.failure.invalid-user-type",
        defaultMessage: "Invalid user type",
      });
    case AuthenticationFailureReasons.NeedsPasswordReset:
      return intlAccessor.formatMessage({
        id: "login.failure.needs-password-reset",
        defaultMessage: "Needs password reset",
      });
    case AuthenticationFailureReasons.AuthenticationOnPrimaryRegionFailed:
      return intlAccessor.formatMessage({
        id: "login.failure.authentication-on-primary-region-failed",
        defaultMessage: "Authentication on primary region failed",
      });
    case AuthenticationFailureReasons.SelectedOrganizationUnitNotAvailable:
      return intlAccessor.formatMessage({
        id: "login.failure.selected-organization-unit-not-available",
        defaultMessage: "Selected organization unit not available",
      });
    case AuthenticationFailureReasons.UserDoesntExist:
      return intlAccessor.formatMessage({
        id: "login.failure.user-does-not-exist",
        defaultMessage: "User does not exist",
      });
    case AuthenticationFailureReasons.UserDeactivated:
      return intlAccessor.formatMessage({
        id: "login.failure.user-deactivated",
        defaultMessage: "User deactivated",
      });
    case AuthenticationFailureReasons.InvalidTwoFactorAuthentication:
      return intlAccessor.formatMessage({
        id: "login.failure.confirmation-token-is-invalid",
        defaultMessage: "Confirmation token is invalid",
      });
    case AuthenticationFailureReasons.None:
    default:
      return undefined;
  }
};
